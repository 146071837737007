<template>
   <main class="main">
      <products-preview />
      <sale-products :products="sellingProducts" />
      <app-subscription />
      <new-products :products="newProducts" />
   </main>
</template>

<script>
   import ProductsPreview from './modules/Preview/ProductsPreview';
   import SaleProducts from './modules/Sliders/SaleProducts';
   import AppSubscription from '@/components/templates/AppSubscription';
   import NewProducts from './modules/Sliders/NewProducts';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'Homepage',

      components: {
         ProductsPreview,
         SaleProducts,
         AppSubscription,
         NewProducts
      },

      inject: ['backToTopOfPage'],

      data() {
         return {
            sections: [
               { stateName: 'sellingProducts', section: 'sections', value: 'sale' },
               { stateName: 'newProducts', section: 'sections', value: 'new-products' }
            ]
         };
      },

      created() {
         this.sections.forEach((item) => {
            this.fetchProducts(item);
         });
      },

      mounted() {
         this.backToTopOfPage();
      },

      methods: {
         ...mapActions('products', { fetchProducts: 'fetchProductsFromCategory' })
      },

      computed: {
         ...mapState('products', ['sellingProducts', 'newProducts'])
      }
   };
</script>
