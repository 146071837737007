<template>
   <section class="sale main__sale">
      <div ref="container" class="container">
         <h2 class="sale__section-title section-title">Распродажа</h2>

         <div v-if="products.length" class="sale__wrap">
            <swiper
               :freeMode="true"
               :navigation="navigation"
               :breakpoints="breakpoints"
               class="sale__cards cards"
            >
               <swiper-slide v-for="product in products" :key="product.id" class="cards__item">
                  <product-card :product="product" class="card--sale" />
               </swiper-slide>
            </swiper>

            <button
               v-show="isDisplayed"
               class="swiper-button-prev prev-btn prev-btn--sale btn"
               aria-label="Перейти к предыдущему слайду"
            ></button>
            <button
               v-show="isDisplayed"
               class="swiper-button-next next-btn next-btn--sale btn"
               aria-label="Перейти к следующему слайду"
            ></button>
         </div>

         <app-notifications v-if="!products.length" notification-type="Loading" />
      </div>
   </section>
</template>

<script>
   import ProductCard from '@/components/templates/ProductCard/ProductCard';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { Swiper, SwiperSlide } from 'swiper/vue';
   import SwiperCore, { Navigation } from 'swiper';

   SwiperCore.use([Navigation]);

   export default {
      name: 'SaleProducts',

      components: {
         ProductCard,
         AppNotifications,
         Swiper,
         SwiperSlide
      },

      props: {
         products: { type: Array, required: true }
      },

      data() {
         return {
            isDisplayed: true,
            navigation: {
               nextEl: '.next-btn--sale',
               prevEl: '.prev-btn--sale'
            },
            breakpoints: {
               320: {
                  slidesPerView: 1
               },
               577: {
                  slidesPerView: 2
               },
               769: {
                  slidesPerView: 3
               },
               993: {
                  slidesPerView: 4
               }
            }
         };
      },

      mounted() {
         const ro = new ResizeObserver((entries) => {
            for (let entry of entries) {
               const width = entry.contentBoxSize[0].inlineSize;
               const breakpoints = Object.entries(this.breakpoints);

               const currentBreakpoint = breakpoints.find((item) => width < +item[0]);

               this.isDisplayed =
                  currentBreakpoint && currentBreakpoint[1].slidesPerView < this.products.length;
            }
         });

         ro.observe(this.$refs.container);
      }
   };
</script>
