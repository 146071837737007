<template>
   <section class="new-products main__new-products">
      <div class="container">
         <h2 class="new-products__section-title section-title">Новинки</h2>

         <div v-if="products.length" class="new-products__wrap">
            <ul v-if="isDisplayed" class="new-products__cards cards">
               <li v-for="product in products" :key="product.id" class="cards__item">
                  <product-card :product="product" class="card--new" />
               </li>
            </ul>

            <div v-if="!isDisplayed" class="new-products__wrap">
               <swiper
                  :freeMode="true"
                  :navigation="navigation"
                  :breakpoints="breakpoints"
                  class="new-products__cards cards"
               >
                  <swiper-slide v-for="product in products" :key="product.id" class="cards__item">
                     <product-card :product="product" class="card--new" />
                  </swiper-slide>
               </swiper>

               <button
                  class="swiper-button-prev prev-btn prev-btn--new btn"
                  aria-label="Перейти к предыдущему слайду"
               ></button>
               <button
                  class="swiper-button-next next-btn next-btn--new btn"
                  aria-label="Перейти к следующему слайду"
               ></button>
            </div>
         </div>

         <app-notifications v-if="!products.length" notification-type="Loading" />
      </div>
   </section>
</template>

<script>
   import ProductCard from '@/components/templates/ProductCard/ProductCard';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { Swiper, SwiperSlide } from 'swiper/vue';
   import SwiperCore, { Navigation } from 'swiper';

   SwiperCore.use([Navigation]);

   export default {
      name: 'NewProducts',

      components: {
         ProductCard,
         AppNotifications,
         Swiper,
         SwiperSlide
      },

      props: {
         products: { type: Array, required: true }
      },

      data() {
         return {
            isDisplayed: true,

            navigation: {
               nextEl: '.next-btn--new',
               prevEl: '.prev-btn--new'
            },
            breakpoints: {
               320: {
                  slidesPerView: 1
               }
            }
         };
      },

      mounted() {
         const mediaQuery = window.matchMedia('(min-width: 577px)');

         mediaQuery.addEventListener('change', this.setDisplayState);
         this.setDisplayState(mediaQuery);
      },

      beforeUnmount() {
         const mediaQuery = window.matchMedia('(min-width: 577px)');

         mediaQuery.removeEventListener('change', this.setDisplayState);
      },

      methods: {
         setDisplayState({ matches }) {
            this.isDisplayed = matches;
         }
      }
   };
</script>
