<template>
   <section class="preview main__preview">
      <div class="container">
         <h2 class="preview__section-title section-title">Популярные категории товаров</h2>

         <div class="preview__wrap">
            <a class="preview__content preview__content--primary" href="#">
               <img
                  class="preview__img"
                  src="@/assets/images/preview/clothes.webp"
                  alt="Детская одежда"
               />
               <span class="preview__description">Детская одежда</span>
            </a>

            <a class="preview__content preview__content--secondary" href="#">
               <img
                  class="preview__img"
                  src="@/assets/images/preview/linen.webp"
                  alt="Постельное белье"
               />
               <span class="preview__description">Постельное белье</span>
            </a>

            <a class="preview__content preview__content--additional" href="#">
               <img
                  class="preview__img"
                  src="@/assets/images/preview/dishes.webp"
                  alt="Детская посуда"
               />
               <span class="preview__description">Детская посуда</span>
            </a>

            <a class="preview__content preview__content--aside" href="#">
               <img
                  class="preview__img"
                  src="@/assets/images/preview/toys.webp"
                  alt="Корзины для игрушек"
               />
               <span class="preview__description">Корзины для игрушек</span>
            </a>
         </div>
      </div>
   </section>
</template>

<script>
   export default {
      name: 'ProductsPreview'
   };
</script>
